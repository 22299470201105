import _ from 'lodash'
import useTranslation from 'next-translate/useTranslation'

const useConfig = (props = {}) => {
  const {} = props
  const { t } = useTranslation()

  const config = {
    apiBasePath: '/client/payment_methods',
    humanName: { singular: t('myCreditCard'), plural: t('myCreditCards') }
  }

  return config
}

export default useConfig
