import useTranslation from 'next-translate/useTranslation'
import toastr from 'toastr'

import { Modal } from '@tootz/react-admin/components'

import { useOrderContext, withOrder } from './context'
import { OrdersPaymentMethodsList } from './PaymentMethods/List'

const ChangeCardModal = ({ onHide, hideAlert = false }) => {
  const { t } = useTranslation()
  const { order } = useOrderContext()

  return (
    <Modal size="lg" show onHide={onHide} centered>
      <Modal.Header onHide={onHide}>
        <Modal.Icon className={`fa-duotone fa-credit-card text-primary`} />
        <Modal.Title>{t('changeCreditCard')}</Modal.Title>
        <Modal.Subtitle>{t('chooseOrSaveANew')}</Modal.Subtitle>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-14">
        {!hideAlert && (
          <div class="alert alert-dismissible bg-light-warning border-warning border border-dashed d-flex flex-column flex-sm-row p-5 mb-6">
            <i className="fad fa-warning fs-2hx text-warning me-5 mb-5 mb-sm-0"></i>
            <div class="d-flex flex-column pe-0 pe-sm-10">
              <h5 class="fw-semibold">{t('warning')}</h5>

              <span>{t('otherInstallmentsAffect')}</span>
            </div>
          </div>
        )}
        <OrdersPaymentMethodsList orderId={order?.id} onFinish={onHide} />
      </Modal.Body>
    </Modal>
  )
}

export default withOrder(ChangeCardModal)
