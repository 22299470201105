import { useEffect } from 'react'

import { useRouter } from 'next/router'

import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { useGatewayServiceContext } from './context'

const StripeProviderInner = ({ children }) => {
  const { current, setProps } = useGatewayServiceContext()
  const stripe = useStripe()
  const elements = useElements()

  if (!process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)
    throw new Error('Stripe Publishable Key Not Found')

  const getCreditCardToken = async ({ client, stripe, elements }) => {
    const resCreateToken = await stripe.createToken(
      elements.getElement(CardElement),
      {
        name: client.name,
        email: client.email
      }
    )

    const { token, error } = resCreateToken

    if (error) throw new Error(error)
    return { token: token?.id, info: token?.card }
  }

  useEffect(() => {
    if (current !== 'stripe' && stripe && elements)
      setProps({
        current: 'stripe',
        getCreditCardToken,
        getCreditCardConfig: { stripe, elements }
      })
  }, [current, stripe, elements])

  return <>{children}</>
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

const StripeProvider = ({ children }) => {
  const { locale } = useRouter()

  const options = {
    locale,
    appearance: {
      theme: 'stripe'
    }
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeProviderInner>{children}</StripeProviderInner>
    </Elements>
  )
}

export { StripeProvider }
