import { createContext, useContext, useState } from 'react'

import { BrazilPaysProvider } from './BrazilPays'
import { LiquidoProvider } from './Liquido'
import { StripeProvider } from './Stripe'

const GatewayServiceContext = createContext(null)

const useGatewayServiceContext = () => useContext(GatewayServiceContext)

const GatewayServiceProvider = ({ gatewayService, children }) => {
  const [props, setProps] = useState({
    getCreditCardToken: () => {
      throw new Error('Get Credit Card Function Not Set')
    }
  })

  // Gateway Services Providers
  const gatewayServices = {
    stripe: StripeProvider,
    liquido: LiquidoProvider,
    brazilpays: BrazilPaysProvider
  }
  const GatewayService = _.get(
    gatewayServices,
    gatewayService,
    ({ children }) => children
  )

  return (
    <GatewayServiceContext.Provider
      value={{ gatewayService, setProps, ...props }}
    >
      <GatewayService>{children}</GatewayService>
    </GatewayServiceContext.Provider>
  )
}

export { useGatewayServiceContext, GatewayServiceProvider }
