import _ from 'lodash'

import allLanguages from './all.json'

const getLanguages = allowed => {
  return allowed
    ? allLanguages.filter(lang => allowed.includes(lang.code))
    : allLanguages
}

export default getLanguages
