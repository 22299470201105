import _ from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { useOrderContext } from '../context'

export const OrdersPaymentMethodsListItem = ({
  id,
  meta: { name, brand, last4, exp_month, exp_year },
  handleDelete,
  handleUseThis,
  tryingToUse,
  payment_order_ids: paymentOrderIds
}) => {
  const { t } = useTranslation()
  const { order } = useOrderContext()

  const current = paymentOrderIds.includes(order?.id)
  const cannotBeDeleted = !_.isEmpty(paymentOrderIds)

  return (
    <div
      class={`card card-dashed flex-row flex-stack flex-wrap p-4 ${
        current ? 'border-gray-600' : ''
      }`}
    >
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center fs-6 fw-bold">
          {name ?? '-'}
          {current && (
            <span class="badge badge-sm badge-light-success ms-2">
              {t('defaultCard')}
            </span>
          )}
        </div>

        <div class="d-flex align-items-center">
          <div>
            <div class="fs-7 fw-bold">
              {t('creditCardLine', {
                last4: last4 || '****',
                brand: brand || 'Card'
              })}
            </div>
            <div class="fs-8 fw-semibold text-gray-600">
              {exp_month &&
                exp_year &&
                `${t('expiresAt')} ${exp_month}/${exp_year}`}
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center py-2">
        {!current && (
          <>
            <button
              type="button"
              class="btn btn-sm btn-light btn-active-light-success me-3"
              onClick={handleUseThis}
              disabled={!!tryingToUse}
            >
              {tryingToUse == id && (
                <i class="fad fa-spinner-third fa-spin me-2 pe-0"></i>
              )}
              {t('useThis')}
            </button>
            <OverlayTrigger
              placement="bottom"
              overlay={
                cannotBeDeleted ? (
                  <Tooltip id={`payment-method-${id}`}>
                    {t('thisCreditCardIsBeingUsedForAnotherPurchase')}
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <div>
                <button
                  type="button"
                  class="btn btn-sm btn-light btn-active-light-primary btn-icon me-3"
                  onClick={handleDelete}
                  disabled={!!tryingToUse || cannotBeDeleted}
                >
                  <div className="fad fa-trash"></div>
                </button>
              </div>
            </OverlayTrigger>
          </>
        )}
      </div>
    </div>
  )
}
