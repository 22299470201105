import { useOrderContext } from '../context'

import { useGatewayServiceContext } from '@/domain/Resources/PurchaseAttempts/GatewayServices/context'
import {
  useResourceRecordContext,
  withResourceRecord,
  useSession
} from '@/packages/@tootz/react-admin'

import getConfig from './config'
import { OrdersPaymentMethodsFields } from './Fields'

export const OrdersPaymentMethodsNew = withResourceRecord(({ onSave }) => {
  const { data: session } = useSession()
  const { Form, isSubmitting } = useResourceRecordContext()
  const {
    current: currentGatewayService,
    getCreditCardToken,
    getCreditCardConfig
  } = useGatewayServiceContext()
  const { order } = useOrderContext()

  const onBeforeSave = async data => {
    let creditCardToken, creditCardInfo

    const { token, info } = await getCreditCardToken({
      client: {
        name: _.get(session?.user, 'name'),
        email: _.get(session?.user, 'email')
      },
      ...getCreditCardConfig
    })

    creditCardToken = token
    creditCardInfo = info

    data = {
      ...data,
      provider: currentGatewayService,
      payable_with: 'credit_card',
      credit_card_meta: creditCardInfo,
      source: creditCardToken,
      payment_order_id: order?.id
    }

    return data
  }

  return (
    <Form onSave={onSave} beforeSave={onBeforeSave}>
      <OrdersPaymentMethodsFields />
      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
        {isSubmitting ? 'Salvando...' : 'Salvar'}
      </button>
    </Form>
  )
}, getConfig)
