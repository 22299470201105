import { useState } from 'react'

import useTranslation from 'next-translate/useTranslation'

import { FormGroup } from '@tootz/react-admin/form'

import { CardElement } from '@stripe/react-stripe-js'

const cardOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d'
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

const OrdersPaymentMethodsFieldsStripe = () => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [disabled, setDisabled] = useState(true)

  const handleChange = async event => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  return (
    <>
      <FormGroup label={t('titles.creditCardData')} name="credit_card" vertical>
        <div className="border rounded-2 p-3">
          <CardElement options={cardOptions} onChange={handleChange} />
        </div>
        {error && (
          <div className="fs-8 text-danger mt-2" role="alert">
            {error}
          </div>
        )}
      </FormGroup>
    </>
  )
}

export { OrdersPaymentMethodsFieldsStripe }
