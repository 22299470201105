import { createContext, useContext } from 'react'

import { GatewayServiceProvider } from '../PurchaseAttempts/GatewayServices/context'

import { useUserCountry } from '@/hooks/useUserCountry'
import { useRecord } from '@/packages/@tootz/react-admin'

const OrderContext = createContext(null)

const useOrderContext = () => useContext(OrderContext)

const OrderProvider = ({ orderId, children }) => {
  const { record: order } = useRecord({ r: '/client/orders', id: orderId })

  const { isBrazil } = useUserCountry()

  const gatewayService =
    isBrazil &&
    ((order?.client?.email || '').includes('@tootz') ||
      (order?.client?.email || '').includes('@mig-tech'))
      ? 'liquido'
      : 'stripe'

  return (
    <OrderContext.Provider value={{ order }}>
      <GatewayServiceProvider gatewayService={gatewayService}>
        {children}
      </GatewayServiceProvider>
    </OrderContext.Provider>
  )
}

const withOrder =
  (Component, config = {}) =>
  componenteProps => {
    return (
      <OrderProvider {...config} {...componenteProps}>
        <Component {...componenteProps} />
      </OrderProvider>
    )
  }

export { useOrderContext, OrderProvider, withOrder }
