import { createContext, useContext, useEffect, useState } from 'react'

import { useUserCountry } from '@/hooks/useUserCountry'

import { useGatewayServiceContext } from './context'

const LiquidoContext = createContext(null)

const useLiquidoContext = () => useContext(LiquidoContext)

const isDev = process.env.NODE_ENV == 'development'
const LIQUIDO_JS_URL = isDev
  ? 'https://js.dev.liquido.com/element.js'
  : 'https://js.liquido.com/element.js'

const LiquidoProvider = ({ children }) => {
  const { language } = useUserCountry()
  const { current, setProps } = useGatewayServiceContext()
  const [liquidoInstance, setLiquidoInstance] = useState(null)
  const [liquidoCard, setLiquidoCard] = useState(null)

  const getCreditCardToken = async ({
    client,
    liquidoInstance,
    liquidoCard
  }) => {
    const resCreateToken = await liquidoInstance.createToken(liquidoCard, {
      name: client.name
    })
    const { card, cardId: token } = resCreateToken.data

    return { token, info: card }
  }

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_LIQUIDO_PUBLISHABLE_KEY)
      throw new Error('Liquido Publishable Key Not Found')

    const script = document.createElement('script')

    script.src = LIQUIDO_JS_URL
    script.async = true

    document.body.appendChild(script)

    script.onload = () => {
      const _liquidoInstance = window.liquido(
        process.env.NEXT_PUBLIC_LIQUIDO_PUBLISHABLE_KEY
      )
      setLiquidoInstance(_liquidoInstance)
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (liquidoInstance && !liquidoCard) {
      const elements = liquidoInstance.elements({
        locale: language,
        country: 'BR'
      })
      const _liquidoCard = elements.create('card')

      setLiquidoCard(_liquidoCard)
    }
  }, [liquidoInstance])

  useEffect(() => {
    if (current !== 'liquido' && liquidoInstance && liquidoCard)
      setProps({
        current: 'liquido',
        getCreditCardToken,
        getCreditCardConfig: { liquidoInstance, liquidoCard }
      })
  }, [current, liquidoInstance, liquidoCard])

  return (
    <LiquidoContext.Provider value={{ liquidoInstance, liquidoCard }}>
      {children}
    </LiquidoContext.Provider>
  )
}

export { useLiquidoContext, LiquidoProvider }
