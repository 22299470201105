import { createContext, useContext, useEffect } from 'react'

import { useGatewayServiceContext } from './context'

const BrazilPaysContext = createContext(null)

const useBrazilPaysContext = () => useContext(BrazilPaysContext)

const isDev = process.env.NODE_ENV == 'development'

const BrazilPaysProvider = ({ children }) => {
  const { current, setProps } = useGatewayServiceContext()

  useEffect(() => {
    if (current !== 'brazilpays')
      setProps({
        current: 'brazilpays'
      })
  }, [current])

  return (
    <BrazilPaysContext.Provider value={{}}>
      {children}
    </BrazilPaysContext.Provider>
  )
}

export { useBrazilPaysContext, BrazilPaysProvider }
