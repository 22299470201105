/* eslint-disable react-hooks/rules-of-hooks */
import { useRouter } from 'next/router'
import Router from 'next/router'
import { signIn, getSession, useSession } from 'next-auth/react'
import setLanguage from 'next-translate/setLanguage'
import { useCookies } from 'react-cookie'

// import { useSession } from '@tootz/react-admin'

import { api } from '@/services/api'

const USER_COUNTRY_COOKIE_KEY = 'migtech.country'
const BRAZIL_ISO3 = 'BRA'
const USA_ISO3 = 'USA'
const BRAZIL_LANGUAGE_CODE = 'pt'
const USA_LANGUAGE_CODE = 'en'
const AVAILABLES_COUNTRIES = [BRAZIL_ISO3, USA_ISO3]
const AVAILABLES_LANGUAGES = [BRAZIL_LANGUAGE_CODE, USA_LANGUAGE_CODE]

const refreshUserSession = async () => {
  const session = await getSession()
  signIn('refresh-session', {
    token: session.accessToken,
    redirect: false
  })
}

export const useUserCountry = () => {
  const [cookies, setCookie] = useCookies([USER_COUNTRY_COOKIE_KEY])
  const { locale } = useRouter()
  const { data: session } = useSession()

  // Country
  const countryFromLocale = locale == 'en' ? USA_ISO3 : BRAZIL_ISO3
  const countryFromCookie = cookies[USER_COUNTRY_COOKIE_KEY]
  let country = countryFromCookie || countryFromLocale

  // Language
  const languageFromLocale = locale
  let language = languageFromLocale

  const clientRole =
    session &&
    session?.user &&
    _.get(session, 'user.roles', []).find(role => !!role.client)
  if (clientRole) {
    const countryFromUser = clientRole?.client?.country
    if (countryFromUser) {
      // if (countryFromCookie != countryFromUser) setCookie(USER_COUNTRY_COOKIE_KEY, countryFromUser)
      country = countryFromUser
    }

    const languageFromUser = clientRole?.client?.language
    if (languageFromUser) {
      // if (languageFromLocale != languageFromUser) setLanguage(languageFromUser)
      language = languageFromUser
    }
  }

  const setUserLanguage = async newLanguage => {
    if (!AVAILABLES_LANGUAGES.includes(newLanguage))
      throw new Error(`${newLanguage} Is Not A Valid Language Code`)

    const session = await getSession()
    const clientRole =
      session &&
      session?.user &&
      _.get(session, 'user.roles', []).find(role => !!role.client)
    if (clientRole) {
      const languageFromUser = clientRole?.client?.language
      if (languageFromUser != newLanguage) {
        await api.put('/user', { record: { language: newLanguage } })
        await refreshUserSession()
      }
    }

    const languageFromLocale = Router.locale
    if (languageFromLocale != newLanguage) setLanguage(newLanguage)
  }

  const setUserCountry = async newCountry => {
    if (!AVAILABLES_COUNTRIES.includes(newCountry))
      throw new Error(`${newCountry} Is Not A Valid Country ISO3 Code`)

    const session = await getSession()
    const clientRole =
      session &&
      session?.user &&
      _.get(session, 'user.roles', []).find(role => !!role.client)
    if (clientRole) {
      const countryFromUser = clientRole?.client?.country
      if (countryFromUser != newCountry) {
        await api.put('/user', { record: { country: newCountry } })
        await refreshUserSession()
      }
    }

    const cookies = new Cookies()
    const countryFromCookie = cookies[USER_COUNTRY_COOKIE_KEY]
    if (countryFromCookie != newCountry)
      setCookie(USER_COUNTRY_COOKIE_KEY, newCountry)
  }

  const setUserCountryAndLanguage = async (newCountry, newLanguage) => {
    if (!AVAILABLES_COUNTRIES.includes(newCountry))
      throw new Error(`${newCountry} Is Not A Valid Country ISO3 Code`)

    if (!AVAILABLES_LANGUAGES.includes(newLanguage))
      throw new Error(`${newLanguage} Is Not A Valid Language Code`)

    const countryFromCookie = cookies[USER_COUNTRY_COOKIE_KEY]
    if (countryFromCookie != newCountry)
      setCookie(USER_COUNTRY_COOKIE_KEY, newCountry)

    const languageFromLocale = Router.locale
    if (languageFromLocale != newLanguage) setLanguage(newLanguage)

    const session = await getSession()
    const clientRole =
      session &&
      session?.user &&
      _.get(session, 'user.roles', []).find(role => !!role.client)
    if (clientRole) {
      const countryFromUser = clientRole?.client?.country
      const languageFromUser = clientRole?.client?.language

      if (countryFromUser != newCountry || languageFromUser != newLanguage) {
        await api.put('/user', {
          record: { country: newCountry, language: newLanguage }
        })
        await refreshUserSession()
      }
    }
  }

  return {
    country,
    language,

    isBrazil: country == BRAZIL_ISO3,
    isUSA: country == USA_ISO3,

    setUserLanguage,
    setUserCountry,
    setUserCountryAndLanguage
  }
}
