import { useState } from 'react'

import useTranslation from 'next-translate/useTranslation'
import toastr from 'toastr'

import { useOrderContext } from '../context'

import {
  useResourceRecordsContext,
  withResourceRecords
} from '@/packages/@tootz/react-admin'
import { api } from '@/services/api'

import getConfig from './config'
import { OrdersPaymentMethodsListItem } from './ListItem'
import { OrdersPaymentMethodsNew } from './New'

export const OrdersPaymentMethodsList = withResourceRecords(({ onFinish }) => {
  const { t } = useTranslation()
  const {
    records: paymentMethods,
    refetch: refetchPaymentMethods,
    handleDelete
  } = useResourceRecordsContext()
  const [showNewPaymentMethodForm, setShowNewPaymentMethodForm] =
    useState(false)
  const { order } = useOrderContext()
  const [tryingToUseThis, setTryingToUseThis] = useState(null)

  const onSaveNewPaymentMethod = () => {
    refetchPaymentMethods()
    setShowNewPaymentMethodForm(false)
    toastr.success(t('newCreditCardWasSetForThisPurchase'), t('done'))
  }

  const onDeletePaymentMethod = async id => {
    await handleDelete(id)
    refetchPaymentMethods()
  }

  const onUseThis = paymentMethodId => {
    setTryingToUseThis(paymentMethodId)
    api
      .put(`client/orders/${order?.id}`, {
        record: { payment_method_id: paymentMethodId }
      })
      .then(() => {
        refetchPaymentMethods()
        toastr.success(t('creditCardWasSetForThisPurchase'), t('done'))
        onFinish()
      })
      .catch(() => {
        toastr.error(t('creditCardWasNotSetForThisPurchase'), t('nothingDone'))
      })
      .finally(() => setTryingToUseThis(null))
  }

  return (
    <>
      <div className="d-flex flex-column gap-3 mb-6">
        {paymentMethods.map(paymentMethod => (
          <OrdersPaymentMethodsListItem
            key={paymentMethod}
            {...paymentMethod}
            handleDelete={() => onDeletePaymentMethod(paymentMethod.id)}
            handleUseThis={() => onUseThis(paymentMethod.id)}
            tryingToUse={tryingToUseThis}
          />
        ))}
      </div>
      {showNewPaymentMethodForm ? (
        <OrdersPaymentMethodsNew onSave={onSaveNewPaymentMethod} />
      ) : (
        <div class="notice d-flex bg-light-success rounded border-success border border-dashed h-lg-100 p-6">
          <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div class="fw-semibold">
              <h6 class="mb-0 text-gray-900 fw-bold">
                {t('addANewCreditCard')}
              </h6>
            </div>

            <button
              class="btn btn-success px-6 align-self-center text-nowrap"
              onClick={() => setShowNewPaymentMethodForm(true)}
            >
              {t('addCard')}
            </button>
          </div>
        </div>
      )}
    </>
  )
}, getConfig)
