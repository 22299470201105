import cc from 'currency-codes'
import _ from 'lodash'
import useTranslation from 'next-translate/useTranslation'

import getLanguages from '../../../services/languages'

const allowedCurrencyCodes = ['BRL', 'USD']

const useConfig = props => {
  const { id, my } = props
  const { t } = useTranslation()

  const basePath = `/orders`

  const paymentMethods = [
    {
      slug: 'recurring',
      name: 'Pagamento recorrente'
    },
    {
      slug: 'credit_card',
      name: 'Pagamento recorrente'
    },
    {
      slug: 'pix',
      name: 'Pagamento recorrente'
    },
    {
      slug: 'bank_slip',
      name: 'Pagamento recorrente'
    }
  ]

  const kinds = [
    { slug: 'course', name: t('course'), icon: 'clapperboard-play' },
    { slug: 'online_event', name: t('onlineCourse'), icon: 'calendar-star' },
    { slug: 'community', name: t('community'), icon: 'users-rays' },
    { slug: 'ebook', name: t('ebook'), icon: 'notebook' },
    { slug: 'audio', name: t('audio'), icon: 'music' },
    { slug: 'movie', name: t('movie'), icon: 'camera-movie' },
    { slug: 'podcast', name: t('podcast'), icon: 'microphone-lines' },
    { slug: 'image', name: t('image'), icon: 'images' },
    { slug: 'files', name: t('files'), icon: 'file-zipper' }
  ]

  const status = [
    { slug: 'draft', name: t('titles.pending'), variant: 'warning' },
    { slug: 'pending', name: t('titles.pending'), variant: 'warning' },
    { slug: 'paid', name: t('titles.paid'), variant: 'success' },
    {
      slug: 'partially_paid',
      name: t('titles.partiallyPaid'),
      variant: 'success'
    },
    {
      slug: 'pending_first_charge',
      name: t('titles.pendingFirstCharge'),
      variant: 'info'
    },
    { slug: 'canceled', name: 'Cancelado', variant: 'danger' },
    { slug: 'refunded', name: 'Reembolsado', variant: 'dark' },
    { slug: 'expired', name: 'Expirado', variant: 'danger' },
    { slug: 'overdue', name: 'Inadimplente', variant: 'danger' },
    {
      slug: 'external',
      name: 'Pago externamente',
      variant: 'success'
    },
    {
      slug: 'failed',
      name: 'Falhou',
      variant: 'danger'
    }
  ]

  const languages = getLanguages(['pt', 'en', 'es', 'fr', 'it']).map(lang => ({
    slug: lang.code,
    name: lang.native
  }))
  const currencies = allowedCurrencyCodes.map(code => {
    const currency = cc.code(code)

    return {
      ...currency,
      slug: currency.code,
      name: currency.currency
    }
  })

  const config = {
    basePath,
    apiBasePath: `/client/orders`,
    icon: 'box-open',
    humanName: {
      singular: t('digitalProduct'),
      plural: t('digitalProducts')
    },
    header: {
      toolbarLinks: [
        {
          text: t('add'),
          url: `${basePath}/new`,
          className: 'btn btn-light-primary btn-sm'
        }
      ]
    },
    getNavView: slug => _.find(navItems, { slug }),
    status,
    getStatus: slug => _.find(status, { slug }),
    kinds,
    getKind: slug => _.find(kinds, { slug }),
    languages,
    getLanguage: slug => _.find(languages, { slug }),
    currencies,
    getCurrency: slug => _.find(currencies, { slug }),
    paymentMethods,
    getPaymentMethod: slug => _.find(paymentMethods, { slug })
  }

  return config
}

export default useConfig
