import _ from 'lodash'
import useTranslation from 'next-translate/useTranslation'

import { useGatewayServiceContext } from '@/domain/Resources/PurchaseAttempts/GatewayServices/context'

import { OrdersPaymentMethodsFieldsLiquido } from './Liquido'
import { OrdersPaymentMethodsFieldsStripe } from './Stripe'

const OrdersPaymentMethodsFields = () => {
  const { t } = useTranslation()
  const { gatewayService } = useGatewayServiceContext()

  const creditCardFields = {
    stripe: OrdersPaymentMethodsFieldsStripe,
    liquido: OrdersPaymentMethodsFieldsLiquido
  }
  const CreditCardFields = _.get(creditCardFields, gatewayService)

  if (!_.has(creditCardFields, gatewayService))
    return <>{t('paymentMethodNotAllowed')}</>
  return <CreditCardFields />
}

export { OrdersPaymentMethodsFields }
