import { useState, useEffect } from 'react'

import useTranslation from 'next-translate/useTranslation'

import { FormGroup } from '@tootz/react-admin/form'

import { useGatewayServiceContext } from '@/domain/Resources/PurchaseAttempts/GatewayServices/context'
import { useLiquidoContext } from '@/domain/Resources/PurchaseAttempts/GatewayServices/Liquido'

const isDev = process.env.NODE_ENV == 'development'

const OrdersPaymentMethodsFieldsLiquido = () => {
  const { t } = useTranslation()
  const { liquidoCard } = useLiquidoContext()
  const {} = useGatewayServiceContext()
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!liquidoCard) return

    liquidoCard.mount('#liquido-card')
    liquidoCard.addEventListener('change', event => {
      setError(event.error ? event.error.message : '')
    })
  }, [liquidoCard])

  return (
    <>
      <FormGroup label={t('titles.creditCardData')} name="credit_card" vertical>
        <div id="liquido-card" className="border rounded-2 p-3"></div>
        {error && (
          <div className="fs-8 text-danger mt-2" role="alert">
            {error}
          </div>
        )}
      </FormGroup>
    </>
  )
}

export { OrdersPaymentMethodsFieldsLiquido }
